/**
 * Util function that tranposes the legacy configs in CS to a shape that the widget
 * expects, which is the same shape returned by the search-widget-api
 *
 * @param {object} config - the PMC config from CS
 * @returns object - a SW config object that the widget expects
 */
export function convertFromCustomerServiceConfig(config, customerCode) {
  const displayDictionary = {};

  for (const key in config.application_string_templates) {
    if (key.startsWith('searchwidget.')) {
      displayDictionary[key] = config.application_string_templates[key];
    }
  }

  return {
    css: config.search_widget.css || '',
    customer_code: customerCode,
    display_dictionary: displayDictionary,
    params: { ...config.search_widget },
    template: {},
    theme: { ...config.theme }
  };
}

/**
 * Util function to do a simple validation of the response from the search-widget-api
 *
 * @param {object} searchWidgetConfig - the config returned from the search-widget-api
 * @returns boolean - true if config is invalid, else false
 */
export function configIsInvalid(searchWidgetConfig) {
  // treat empty object "{}" as undefined
  if (!searchWidgetConfig || Object.keys(searchWidgetConfig).length === 0) {
    return true;
  } else {
    return false;
  }
}

import React, { SyntheticEvent } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  AvailabilityTileSize,
  ProviderAvailability,
  Slot,
  getProviderDisplayName,
  getProviderImageUrl,
  getProviderContactPhone,
  AppointmentInfo
} from '@kyruus/provider-components';

import Theme from '@kyruus/ui-theme';
import Link from '@kyruus/link';
import { PMCConfig } from '@kyruus/types';
import { FormattedMessage } from '@kyruus/intl';
import { formatAvailabilityForTimeTiles } from '@kyruus/availability-tiles';

import { AppointmentSlot, ExtendedProvider, Log } from 'Src/types';
import {
  getSchedulingVisibility,
  getAppointmentOptions
} from 'Src/utils/availability';

import { SideSheet } from '../../../search-v9/SideSheet';
import {
  Header,
  ProviderSummary,
  ProviderImage,
  ProviderName,
  ProviderSpecialties
} from './styles';
import { messages } from './messages';
import { AVAILABILITY_DRAWER } from '../constants';
import { TRACKING_EVENTS, MAX_AVAILABILITIES_TO_DISPLAY } from './constants';

interface AvailabilityDrawerProps {
  bookOnlineUrl: string;
  config: PMCConfig;
  provider: ExtendedProvider;
  appointmentInfo: AppointmentInfo;
  onClose: (providerId: number, reason: string) => void;
  log: Log;
  onViewMoreClick: (providerId: number, href: string) => void;
  fetchProviderAvailability: (
    providerIds: number,
    appointmentInfo: AppointmentInfo
  ) => Promise<{ slots: AppointmentSlot[]; error?: boolean }>;
  selectedDate: string;
  onTimeSlotsClick: (
    date: string | undefined,
    time: string | undefined,
    providerId: number,
    href: string,
    unformattedDate: string,
    provider: ExtendedProvider
  ) => void;
}

export const AvailabilityDrawer = ({
  appointmentInfo,
  bookOnlineUrl,
  config,
  provider,
  onClose,
  log,
  onViewMoreClick,
  fetchProviderAvailability,
  selectedDate,
  onTimeSlotsClick
}: AvailabilityDrawerProps) => {
  const handleOnClose = (_e: SyntheticEvent, reason: string) => {
    onClose(provider.id, reason);
  };
  const handlePhoneNumberClick = () => {
    log(TRACKING_EVENTS.PHONE_NUMBER_CLICK, {
      providerId: provider.id
    });
  };
  const handleTimeSlotsClick = (
    date: string | undefined,
    time: string | undefined,
    providerId: number,
    href: string,
    unformattedDate: string
  ) => {
    onTimeSlotsClick(date, time, providerId, href, unformattedDate, provider);
  };

  const isScreenMediumOrBelow = useMediaQuery(
    `(max-width:${Theme.screen_medium})`
  );

  const providerName = getProviderDisplayName(provider);
  const phoneNumber = getProviderContactPhone(provider);
  const { purposeOptionsByRelationship, initialAppointmentInfo } =
    getAppointmentOptions(
      config,
      provider,
      appointmentInfo,
      getSchedulingVisibility(config)
    );

  const specialties = provider
    ? provider.specialties
        .filter((specialty) => !!specialty.name)
        .map((specialty) => specialty.name)
        .join(', ')
    : '';

  const DrawerFooter = phoneNumber ? (
    <FormattedMessage
      {...messages.phoneNumber}
      values={{
        phone: (
          <Link href={`tel:${phoneNumber}`} onClick={handlePhoneNumberClick}>
            {phoneNumber}
          </Link>
        )
      }}
    />
  ) : undefined;

  return (
    <SideSheet
      ariaLabelledBy="availability-drawer-title"
      dataTestId={AVAILABILITY_DRAWER.ID}
      footer={DrawerFooter}
      header={
        <FormattedMessage {...messages.drawerTitle}>
          {(message) => (
            <Header id="availability-drawer-title">{message}</Header>
          )}
        </FormattedMessage>
      }
      id={AVAILABILITY_DRAWER.ID}
      onClose={handleOnClose}
      width={isScreenMediumOrBelow ? '100vw' : '580px'}
    >
      <ProviderSummary>
        <ProviderImage alt={providerName} src={getProviderImageUrl(provider)} />
        <ProviderName>{providerName}</ProviderName>
        <ProviderSpecialties>{specialties}</ProviderSpecialties>
      </ProviderSummary>
      <ProviderAvailability
        appointmentInfo={initialAppointmentInfo}
        locations={provider.locations}
        loggingFunction={log}
        maxAvailabilitiesToDisplay={MAX_AVAILABILITIES_TO_DISPLAY}
        provider={provider}
        purposeOptionsByRelationship={purposeOptionsByRelationship}
        selectedDate={selectedDate}
        services={{
          formatAvailability: (slots: Slot[]) =>
            formatAvailabilityForTimeTiles(slots, bookOnlineUrl, false),
          fetchAvailability: fetchProviderAvailability
        }}
        shouldRenderSlotsAsButtons={false}
        tileSize={AvailabilityTileSize.SMALL}
        onTimeSlotsClick={(
          date: string | undefined,
          time: string | undefined,
          providerId: number,
          href: string,
          unformattedDate: string
        ) =>
          handleTimeSlotsClick(date, time, providerId, href, unformattedDate)
        }
        bookOnlineUrl={bookOnlineUrl}
        onViewMoreClick={(_providerId: number, href: string) =>
          onViewMoreClick(provider.id, href)
        }
      />
    </SideSheet>
  );
};

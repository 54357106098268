// constants for the values of the `from` url param when passing from providers list to profile pages
export const FROM_SEARCH_LIST = 'search-list';
export const FROM_SEARCH_MAP = 'search-map';
export const FROM_LOCATIONS = 'locations';

export const BOOKING_MODAL = {
  ID: 'appt-request',
  ROLE: 'dialog'
};

export const AVAILABILITY_DRAWER = {
  ID: 'availability-drawer',
  ROLE: 'dialog'
};

export const TILE_CLICK_ORIGIN = {
  PROVIDER_TILE: 'provider-tile',
  AVAILABILITY_DRAWER: 'availability-drawer'
};

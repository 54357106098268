import React from 'react';
import { arrayOf, bool, element, func, node, number, string } from 'prop-types';

import Modal from '@kyruus/modal';

import ModalBody from './modal-body';

class CtaModal extends React.Component {
  constructor() {
    super();

    this.shouldRenderModalFullScreenListener =
      this.shouldRenderModalFullScreenListener.bind(this);

    this.state = { shouldRenderModalFullscreen: false };
  }

  componentDidMount() {
    if (window && window.matchMedia) {
      this.modalFullScreenMq = window.matchMedia('(max-width: 420px)');

      this.setState({
        shouldRenderModalFullscreen: this.modalFullScreenMq.matches
      });

      this.modalFullScreenMq.addListener(
        this.shouldRenderModalFullScreenListener
      );
    }
  }

  componentWillUnmount() {
    if (this.modalFullScreenMq) {
      this.modalFullScreenMq.removeListener(
        this.shouldRenderModalFullScreenListener
      );
    }
  }

  shouldRenderModalFullScreenListener({ matches }) {
    this.setState({
      shouldRenderModalFullscreen: matches
    });
  }

  render() {
    const {
      ariaLabelledBy,
      id,
      onClose,
      open,
      panelCount,
      panels,
      role,
      title
    } = this.props;
    return (
      <Modal
        id={id}
        role={role}
        headerTestId="ctaModalHeader"
        closeBtnTestId="ctaModalCloseBtn"
        aria-labelledby={ariaLabelledBy}
        // modal renders outside the pmc-app container
        // so we need to add this class to ensure our styles can target the modal contents
        className="kyruus-custom"
        title={title}
        body={<ModalBody panels={panels} />}
        open={open}
        onClose={onClose}
        maxWidth={
          {
            1: 'xs',
            2: 'md',
            3: 'lg',
            4: 'xl'
          }[panelCount]
        }
        topAligned={true}
        fullScreen={this.state.shouldRenderModalFullscreen}
      />
    );
  }
}

CtaModal.propTypes = {
  ariaLabelledBy: string,
  id: string.isRequired,
  onClose: func,
  open: bool,
  panelCount: number,
  panels: arrayOf(element).isRequired,
  role: string,
  title: node.isRequired
};

export default CtaModal;

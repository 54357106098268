import { createMachine, assign } from 'xstate';
import * as states from './location-summary-states';
import {
  DISPLAY_PROVIDERS,
  HIDE_PROVIDERS,
  LOAD_MORE,
  NEW_SEARCH
} from './location-summary-actions';

// outline for the context of the machine. facilitates proper
// initialization/error handling on startup
const emptyContext = {
  locationId: undefined,
  locationObject: undefined,
  fetchLocation: () => {}
};

/**
 * Returns the machine JSON for location-summary. Exported to facilitate testing
 * @param {object} initialContext the initial context for the machine
 * @returns object
 */
export const machineJSON = (initialContext = emptyContext) => {
  const {
    locationId,
    locationObject,
    fetchLocation,
    fetchProvidersByLocation,
    currentQuery
  } = initialContext;
  return {
    id: 'location-summary',
    initial: states.initializing,
    context: {
      locationId: locationId,
      location: locationObject,
      fetchLocation: fetchLocation,
      fetchProvidersByLocation: fetchProvidersByLocation,
      providers: [],
      totalProviders: 0,
      currentQuery,
      page: 1,
      fetchProvidersError: false
    },
    states: {
      [states.initializing]: {
        always: [
          { target: states.idle, cond: 'hasLocationInContext' },
          { target: states.loading, cond: 'hasLocationIdInContext' },
          { target: states.error }
        ]
      },
      [states.loading]: {
        invoke: {
          id: 'fetchLocationThroughStore',
          src: 'fetchLocationThroughStore',
          onDone: {
            target: states.idle,
            actions: 'onFetchLocationSuccess'
          },
          onError: {
            target: states.error,
            actions: 'onFetchLocationError'
          }
        }
      },
      [states.idle]: {
        on: {
          [DISPLAY_PROVIDERS]: {
            target: states.displayingProviders
          }
        }
      },
      [states.error]: {},
      [states.displayingProviders]: {
        initial: states.loading,
        states: {
          [states.loading]: {
            invoke: {
              id: 'fetchProvidersThroughStore',
              src: 'fetchProvidersThroughStore',
              onDone: {
                actions: 'onFetchProvidersSuccess',
                target: states.idle
              },
              onError: {
                target: states.error,
                actions: 'onFetchProvidersError'
              }
            }
          },
          [states.idle]: {
            actions: {},
            on: {
              [NEW_SEARCH]: {
                actions: 'setCurrentQuery',
                target: states.loading
              },
              [LOAD_MORE]: {
                actions: 'incrementPage',
                target: states.loading
              }
            }
          },
          [states.error]: {}
        },
        on: {
          [HIDE_PROVIDERS]: {
            target: states.idle
          }
        }
      }
    }
  };
};

const machineActions = {
  onFetchLocationSuccess: assign({
    // the location that comes back from location service is wrapped in _result
    location: (context, event) => event.data._result
  }),
  onFetchLocationError: assign({
    location: null,
    error: (context, event) => event
  }),
  onFetchProvidersSuccess: assign({
    providers: (ctx, ev) => {
      return ev.data._result.map((item) => {
        return {
          ...item,
          provider: {
            ...item.provider,
            has_provider_availability: !!item.availability?.slots?.length
          }
        };
      });
    },
    totalProviders: (ctx, ev) => ev.data._metadata.total_providers,
    fetchProvidersError: false
  }),
  onFetchProvidersError: assign({
    fetchProvidersError: true
  }),
  setCurrentQuery: assign({
    currentQuery: (ctx, ev) => ev.data.currentQuery
  }),
  incrementPage: assign({
    page: (ctx) => {
      const newPage = ctx.page + 1;
      return newPage;
    }
  })
};

const guards = {
  hasLocationIdInContext: (context) => Boolean(context.locationId),
  hasLocationInContext: (context) => Boolean(context.location)
};

const services = {
  fetchLocationThroughStore: async (context) => {
    const { locationId, fetchLocation } = context;

    // this is the same fetch function that redux uses, modified slightly
    // so it returns the data rather than just call dispatch. it returns
    // a Promise, so works as-is as a service in xstate
    const locationData = await fetchLocation(locationId);

    return locationData;
  },
  fetchProvidersThroughStore: async (context) => {
    const { locationId, fetchProvidersByLocation, currentQuery, page } =
      context;

    // send full query to redux in case in future users can manipulate more than just `sort`
    const providerData = await fetchProvidersByLocation(locationId, {
      ...currentQuery,
      page
    });

    return providerData;
  }
};

/**
 * Creates a location summary machine that powers the location-summary page
 * @param {object} initialContext should contain these keys: locationId (string), locationObject (object, optional), fetchLocation (func)
 * @returns xstate.Machine
 */
export const createLocationSummaryMachine = (initialParams) => {
  const initialContext = { ...emptyContext, ...initialParams };
  return createMachine(machineJSON(initialContext)).withConfig({
    actions: machineActions,
    guards: guards,
    services: services
  });
};

import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo
} from 'react';
import { injectIntl, FormattedMessage } from '@kyruus/intl';
import Cancel from '@kyruus/react-ikons/Cancel';
import {
  isValidTypeaheadCategory,
  isValidTypeaheadCategoryV8
} from '@kyruus/search-widget-v2';
import { pages, placements } from '../tracking/constants';
import { pageViewEvent } from '../tracking/tracking-utils';
import {
  findInitialTypeaheadTerms,
  getLocationValue,
  getInitialPreFiltersValue
} from '../utils/search-common';
import {
  getStickyParamsFromLocation,
  getStartNewSearchUrl
} from '../utils/url';
import { isCCF } from '../utils/cerner';
import { SearchWidget, PreFilters } from './search-widget';
import { messages } from './messages';
import { PERSONAS, getConfigProperty } from 'Common/config';
import {
  StartNewSearchLink,
  SearchContainer,
  SearchHomeFilterBanner
} from '../search-v9/styles';

const getLoggingMetaData = () => {
  return {
    page: pages.SEARCH
  };
};

/**
 * Search widget usually handles this config automatically
 * But since we're controlling the `onSubmit` call, we need
 * manually handle it here
 */
const shouldOpenInNewTab = ({ config, searchWidgetConfig }) =>
  config.darkship_KENG_31725
    ? Boolean(searchWidgetConfig?.params?.open_results_in_new_tab)
    : Boolean(config.search_widget?.open_results_in_new_tab);

/**
 * enable_pre_search_filters flag determines whether pre-filters are enabled.
 * @param {Object} config customer config object
 * @returns `true` or `false`
 */
const shouldAllowPreFilters = (config) => {
  return Boolean(config?.search_widget?.enable_pre_search_filters);
};

/**
 *  enable pre filters above the searchwidget
 *   - loads a widget with extra features around it
 *  that aren't supported by `search widget admin` yet
 */
const shouldShowPreFilters = ({ config, location }) => {
  return location?.pathname === '/' && shouldAllowPreFilters(config);
};

/**
 *
 * Start a new search link is displayed for `Employed Users` from the listing page
 */
export const shouldShowStartNewSearchLink = ({ config, location }) => {
  return (
    getConfigProperty(config, 'persona') === PERSONAS.EMPLOYED_USER &&
    !(location?.pathname === '/')
  );
};

/**
 * @param {Object} config customer config object
 * @returns `true` if pre-filters are set in search_home config for PMEU persona otherwise `false`
 */
const shouldAddSearchHomeFilters = (config) => {
  return (
    getConfigProperty(config, 'persona') === PERSONAS.EMPLOYED_USER &&
    config.search_home?.split('?', 2).length === 2
  );
};

const buildQueryParams = ({
  incomingSearch,
  config,
  preFilters,
  locationParams
}) => {
  const queryParams = new URLSearchParams(incomingSearch);
  const useSearchV9ListPage = config.darkship_use_list_page_searchv9;

  /**
   * Add filters from search_home config
   */
  if (shouldAddSearchHomeFilters(config)) {
    const searchHome = config.search_home?.split('?', 2);
    const searchHomeParams = new URLSearchParams(searchHome[1]);
    searchHomeParams.getAll('filter').forEach((searchHomeFilter) => {
      queryParams.append('filter', searchHomeFilter);
    });
  }

  if (shouldAllowPreFilters(config)) {
    if (preFilters.acceptingNewPatients) {
      queryParams.append(
        'filter',
        useSearchV9ListPage
          ? 'provider.accepting_new_patients:true'
          : 'accepting_new_patients:T'
      );
    }
    if (preFilters.selectedInsurance) {
      queryParams.append(
        'filter',
        useSearchV9ListPage
          ? `provider.insurance_accepted:${preFilters.selectedInsurance}`
          : `insurance_plans:${preFilters.selectedInsurance}`
      );
    }
  }

  for (const key in locationParams) {
    queryParams.append(key, locationParams[key]);
  }

  return queryParams;
};

export const getQueryBaseAndParams = (query, location, config, preFilters) => {
  const [queryBase, incomingSearch] = query.split('?', 2);
  const locationParams = getStickyParamsFromLocation(location);
  const queryParams = buildQueryParams({
    config,
    incomingSearch,
    preFilters,
    locationParams
  });

  return {
    queryBase,
    queryParams
  };
};

export const getTypeaheadCategoryEntry = (queryParams) => {
  const typeaheadCategoryEntry = Array.from(queryParams.entries()).find(
    ([key]) => isValidTypeaheadCategory(key) || isValidTypeaheadCategoryV8(key)
  );

  return typeaheadCategoryEntry;
};

const SearchWidgetPage = ({
  /** react router history */
  history,
  /** Configuration that comes from customer service. Includes search widget options*/
  config,
  /** Search widget configuration that comes from the search-widget-admin api. Behind a darkship flag */
  searchWidgetConfig,
  /** the customerCode from state */
  customerCode,
  /** window location */
  location,
  /** analytics tracking function */
  log,
  /** if this component is mounted with a placement of `SEARCH`, it means that no routes were matched and we're in "/" **/
  placement,
  /** What gets rendered __after__ the search widget */
  children,
  intl
}) => {
  const [typeaheadTerms, setTypeaheadTerms] = useState(undefined);
  const [searchLocation, setSearchLocation] = useState(undefined);
  const loggingMetadata = useRef(getLoggingMetaData(config));
  const [preFiltersState, setPreFiltersState] = useState(
    getInitialPreFiltersValue(config)
  );

  // Only read the pre-filters on the homepage. This ensures if the user
  // navigates back to the homepage, their selection persists, but we do not re-assign
  // them on fresh searches on the search page
  const preFilters = useMemo(() => {
    if (location?.pathname === '/') return preFiltersState;
    return {};
  }, [preFiltersState, location?.pathname]);

  useEffect(() => {
    // because of the way the router is setup:
    // if this component is mounted within a `search results` page
    // the tracking will be triggered from `SearchContainer`
    // if this component is mounted with a placement of `SEARCH` it means
    // that no routes were matched and we're in "/"
    if (placement === placements.SEARCH) {
      log(pageViewEvent(pages.SEARCH), loggingMetadata.current);
    }
  }, []);
  useEffect(() => {
    // log pre-filters selection
    if (shouldShowPreFilters({ config, location }) && preFilters) {
      log('user_action.search.pre_filters', {
        selected_prefilters: JSON.stringify(preFilters)
      });
    }
  }, [preFilters, config, location, log]);

  const searchString = location ? location.search : '';

  useEffect(() => {
    // updates the search widget text when the search url gets updated
    setTypeaheadTerms(findInitialTypeaheadTerms(searchString));
    setSearchLocation(getLocationValue(searchString));
  }, [searchString]);

  const handlePreFilterChange = (val) => {
    setPreFiltersState((prev) => ({ ...prev, ...val }));
  };

  /**
   * TODO: re-evaluate this for https://kyruus.jira.com/browse/KENG-34729
   *
   * Handles onSelect method of search widget when auto search on select is disabled.
   * For now, we want to disable the automatic search when selecting
   * an option from the typeahead. However, we want to preserve the
   * selected typeahead category when the search is actually performed,
   * so we capture it here and replace the `unified` query param with
   * the one selected.
   *
   * This will be revisited once pre-filters are made part
   * of the search widget and controlled through the admin UI.
   */
  const [typeaheadCategoryParam, setTypeaheadCategoryParam] = useState();

  const trackTypeaheadCategorySelect = useCallback(
    ([category, value]) =>
      log(
        'user_action.search_page.search_widget.typeahead_suggestion_clicked',
        {
          category,
          value
        }
      ),
    [log]
  );

  const handleSelectWithDisabledSearch = useCallback(
    (query) => {
      const { queryParams } = getQueryBaseAndParams(
        query,
        location,
        config,
        preFilters
      );
      const typeaheadCategoryEntry = getTypeaheadCategoryEntry(queryParams);

      if (typeaheadCategoryEntry) {
        trackTypeaheadCategorySelect(typeaheadCategoryEntry);
        setTypeaheadCategoryParam(typeaheadCategoryEntry);
      }
    },
    [preFilters, config, location, trackTypeaheadCategorySelect]
  );
  const handleSelect = useCallback(
    (query) => {
      const { queryBase, queryParams } = getQueryBaseAndParams(
        query,
        location,
        config,
        preFilters
      );
      const typeaheadCategoryEntry = getTypeaheadCategoryEntry(queryParams);

      if (typeaheadCategoryEntry) {
        trackTypeaheadCategorySelect(typeaheadCategoryEntry);
      }

      // *Open searches in a new tab:*
      const path = `${queryBase}?${queryParams.toString()}`;
      if (shouldOpenInNewTab({ config, searchWidgetConfig })) {
        window.open(path, '_blank');
      } else {
        history.push(path);
      }
    },
    [
      preFilters,
      config,
      location,
      history,
      searchWidgetConfig,
      trackTypeaheadCategorySelect
    ]
  );
  const handleSubmit = useCallback(
    (query) => {
      const { queryBase, queryParams } = getQueryBaseAndParams(
        query,
        location,
        config,
        preFilters
      );

      log('user_action.search_page.search_widget.search_button');

      // TODO: re-evaluate this for https://kyruus.jira.com/browse/KENG-34729
      // As mentioned above, if the user makes a selection from the typeahead
      // and the automatic search is disabled, replace the default `unified`
      // typeahead category query param with the one that was selected.
      if (typeaheadCategoryParam) {
        queryParams.delete('unified');
        queryParams.set(...typeaheadCategoryParam);
        setTypeaheadCategoryParam(undefined);
      }

      // *Open searches in a new tab:*
      const path = `${queryBase}?${queryParams.toString()}`;
      if (shouldOpenInNewTab({ config, searchWidgetConfig })) {
        window.open(path, '_blank');
      } else {
        history.push(path);
      }
    },
    [
      preFilters,
      config,
      location,
      typeaheadCategoryParam,
      history,
      log,
      searchWidgetConfig
    ]
  );

  /**
   * Flag to determine if auto search is enabled on selecting a suggestion from the typeahead.
   * By default auto search is enabled (currently it is disabled for agents and employed users).
   */
  const enableAutoSearchOnSelectSuggestion =
    config?.search_widget?.enable_auto_search_on_select_suggestion ?? true;

  // 'Start a new search' Link from provider listing page logs an event on click
  const handleStartANewSearchClick = () => {
    /**
     * This is to reset pre-filters to default when `start a new search` link is clicked.
     * `preFilters` state is only maintained for the home page
     */
    setPreFiltersState(getInitialPreFiltersValue(config));
    log(`user_action.search.listing.start.new_search`, {
      search_string: location?.search
    });
  };

  const useSearchV9ListPage = config.darkship_use_list_page_searchv9;
  const searchContainerClass = !useSearchV9ListPage ? 'container' : '';

  return (
    <SearchContainer
      className={`${searchContainerClass} kyruus-custom pt-m pb-m`}
      v9={useSearchV9ListPage}
    >
      {shouldShowStartNewSearchLink({ config, location }) && (
        <StartNewSearchLink
          id="new-search"
          to={getStartNewSearchUrl(config)}
          onClick={handleStartANewSearchClick}
        >
          <FormattedMessage {...messages.startNewSearch} />
        </StartNewSearchLink>
      )}
      {shouldShowPreFilters({ config, location }) && (
        <PreFilters
          config={config}
          filters={preFilters}
          onChange={handlePreFilterChange}
        />
      )}
      <SearchWidget
        config={config}
        searchWidgetConfig={searchWidgetConfig}
        customerCode={customerCode}
        options={{
          noScrollDropdownResults: isCCF(),
          // This search widget will adjust its size based on `media queries` and not
          // the container is rendered in
          shouldUseContainerQuery: false
        }}
        typeaheadTerms={typeaheadTerms}
        location={searchLocation}
        onSelect={
          enableAutoSearchOnSelectSuggestion
            ? handleSelect
            : handleSelectWithDisabledSearch
        }
        onSubmit={handleSubmit}
        onInputFocus={() =>
          log('user_action.search_page.search_widget.search_field')
        }
        productName="pmc"
      />
      {shouldAddSearchHomeFilters(config) && (
        <SearchHomeFilterBanner
          data-testid="search-home-filters-banner"
          type="info"
          icon="!"
          action1Text={<Cancel />}
          action1="close"
        >
          <FormattedMessage
            {...messages.searchHomeFilters}
            values={{
              clearAllFilters: (
                <b>{intl.formatMessage(messages.clearfilters)}</b>
              )
            }}
          />
        </SearchHomeFilterBanner>
      )}

      {children}
    </SearchContainer>
  );
};
export default injectIntl(SearchWidgetPage);

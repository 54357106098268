import styled from '@emotion/styled';
import { fromTheme } from '@kyruus/ui-theme';

export const DrawerContent = styled.div<{ width: string }>`
  width: ${(props) => props.width};
`;

export const Content = styled.div`
  padding: ${fromTheme('spacing_large')};
  height: 100vh;
  overflow-y: auto;
`;

export const Header = styled.div`
  position: sticky;
  top: 0;

  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${fromTheme('color_background')};
  border-bottom: 1px solid ${fromTheme('color_border')};
  padding: ${fromTheme('spacing_small')} ${fromTheme('spacing_large')};

  > button {
    align-self: flex-start;
  }
`;

export const Footer = styled.div`
  position: sticky;
  bottom: 0;

  background: ${fromTheme('color_background')};
  border-top: 1px solid ${fromTheme('color_border')};
  padding: ${fromTheme('spacing_medium')} ${fromTheme('spacing_large')};
`;

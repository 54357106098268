import _get from 'lodash/get';
import {
  PMCConfig,
  ProviderMatchProviderV9,
  ProviderMatchAppointmentEhrPurposeVisibilities,
  Visibility
} from '@kyruus/types';
import {
  PurposeOptionsByRelationship,
  PurposeOption,
  VisitType,
  AppointmentInfo,
  PatientRelationship
} from '@kyruus/provider-components';

import { splitOnce } from 'Common/utils/splitOnce';
import {
  SCHEDULING_VISIBILITY_CONSUMER,
  NEW_PATIENT,
  ESTABLISHED_PATIENT
} from './constants';
import { getVisibility } from './search-common';

export const getSchedulingVisibility = (config: PMCConfig) => {
  const { schedulingVisibility } = getVisibility(config);
  return schedulingVisibility;
};

const getUseDefaultAppointmentPurpose = (
  config: PMCConfig,
  provider: ProviderMatchProviderV9
) => {
  const isPrimaryCare = provider.is_primary_care ?? false;
  const isSpecialtyCare = provider.is_specialty_care ?? false;

  if (isSpecialtyCare) {
    return _get(
      config,
      'feature_flags.provider_specialty_booking_use_default_appt_purpose',
      false
    );
  } else if (isPrimaryCare) {
    return _get(
      config,
      'feature_flags.provider_pcp_booking_use_default_appt_purpose',
      true
    );
  }

  return false;
};

const sortByNameAndSetDefault = (
  purposes: PurposeOption[],
  setDefault: boolean
) => {
  const sortedPurposes = purposes.sort((a, b) => a.name.localeCompare(b.name));

  if (setDefault) {
    sortedPurposes[0].default = true;
  }
  return sortedPurposes;
};

/**
 * Given a provider, will return a list of purpose options by patientRel
 * @param {ProviderMatchProviderV9} provider
 */
export const getAppointmentOptions = (
  config: PMCConfig,
  provider: ProviderMatchProviderV9,
  appointmentInfo: AppointmentInfo,
  visibility = SCHEDULING_VISIBILITY_CONSUMER
): {
  purposeOptionsByRelationship: PurposeOptionsByRelationship;
  initialAppointmentInfo: AppointmentInfo;
} => {
  const purposeByRel: PurposeOptionsByRelationship = {
    [NEW_PATIENT]: [],
    [ESTABLISHED_PATIENT]: []
  };
  const [visKey, visValue] = splitOnce(visibility, ':') || ['', ''];
  const ehrPurposes = provider.appointment_ehr_purposes || [];

  const visibilityKey =
    visKey as keyof ProviderMatchAppointmentEhrPurposeVisibilities;
  const visibilityValue = visValue as Visibility;

  ehrPurposes.forEach(({ ehr_data, name, patient_relationship }) => {
    const isVisible = ehr_data?.find(
      ({ visibilities }) => visibilities[visibilityKey] === visibilityValue
    );

    if (
      isVisible &&
      [ESTABLISHED_PATIENT, NEW_PATIENT].includes(patient_relationship)
    ) {
      if (
        !purposeByRel[patient_relationship].find(
          (purpose: PurposeOption) => purpose.name === name
        )
      ) {
        purposeByRel[patient_relationship].push({
          name,
          visit_method: isVisible.visit_method as VisitType,
          default: false
        });
      }
    }
  });

  const useDefaultPurpose = getUseDefaultAppointmentPurpose(config, provider);
  const setNewPurposeDefault = useDefaultPurpose && purposeByRel.new.length > 0;
  const setEstablishedPurposeDefault =
    useDefaultPurpose && !setNewPurposeDefault;

  const purposeOptionsByRelationship = {
    new: sortByNameAndSetDefault(purposeByRel.new, setNewPurposeDefault),
    established: sortByNameAndSetDefault(
      purposeByRel.established,
      setEstablishedPurposeDefault
    )
  };
  let initialAppointmentInfo: AppointmentInfo = appointmentInfo;
  if (useDefaultPurpose) {
    if (setNewPurposeDefault) {
      initialAppointmentInfo = {
        purpose: purposeOptionsByRelationship.new[0].name,
        relationship: PatientRelationship.NEW
      };
    } else {
      initialAppointmentInfo = {
        purpose: purposeOptionsByRelationship.established[0].name,
        relationship: PatientRelationship.ESTABLISHED
      };
    }
  }

  return {
    purposeOptionsByRelationship,
    initialAppointmentInfo
  };
};

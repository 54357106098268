import { configSelector } from '../behaviors/configuration';
import { getProfileById } from '../behaviors/profiles';

import { pages } from '../tracking/constants';

/**
 * @param {Object} routeMatch
 * @returns {String}
 * Returned id will be -1 if it isn't a 1-10 character long digit;
 * This is to prevent malformed provider or location summary page URLs from sending broken id's to analytics.
 */
const getProviderIdOrInvalidate = (routeMatch) => {
  const idToValidate =
    routeMatch.params && routeMatch.params.id ? routeMatch.params.id : -1;
  const isValid = /^[0-9]{1,10}$/.test(idToValidate);
  const id = isValid ? idToValidate : -1;
  return id;
};

/**
 * @param {Object} routeMatch
 * @returns {String}
 * Returned id will be -1 if it isn't a 1-10 character long digit;
 * This is to prevent malformed provider or location summary page URLs from sending broken id's to analytics.
 */
const getLocationIdOrInvalidate = (routeMatch) => {
  const idToValidate =
    routeMatch.params && routeMatch.params.id ? routeMatch.params.id : -1;
  const isValid = /^LOC[0-9]{1,10}$/i.test(idToValidate);
  const id = isValid ? `${idToValidate}`.toLowerCase() : -1;
  return id;
};

//
// NOTE:
// DECORATE_HOME_TRACKING for home-container.jsx won't work due to how the router is setup.
// See note on providermatch_consumer/static/src/shared/search-widget-page.jsx
// Instead, we do the equivalent there in ``getLoggingMetaData()``
//

/**
 * @param {String} message
 * @param {Object} state
 * @returns {Object}
 */
export const decorateSearchTracking = () => {
  return {
    page: pages.SEARCH_RESULTS
  };
};

/**
 * @param {String} message
 * @param {Object} state
 * @param {Object} routeMatch "match" from react-router or an object containing params
 * @returns {Object} returns {...loggingMeta and provider_id}
 */
export const decorateProfileTracking = ({ state, routeMatch = {} }) => {
  const config = configSelector(state);
  const providerId = getProviderIdOrInvalidate(routeMatch);
  const useProfileSearchV9 = Boolean(config.darkship_use_profile_searchv9);

  let provider;
  let providerName;
  if (providerId && providerId > 0) {
    provider = getProfileById(state, providerId);

    if (provider) {
      if (useProfileSearchV9) {
        provider = provider.provider;
        providerName = provider?.name?.full;
      } else {
        provider = provider.profile;
        providerName = provider?.name?.full_name;
      }
    }
  }
  return {
    provider_id: providerId,
    provider_name: providerName,
    page: pages.PROVIDER_PROFILE
  };
};

/**
 * @param {String} message
 * @param {Object} state
 * @param {Object} routeMatch "match" from react-router or an object containing params
 * @returns {Object} returns {...loggingMeta and location_id}
 */
export const decorateLocationTracking = ({ routeMatch = {} }) => {
  return {
    location_id: getLocationIdOrInvalidate(routeMatch),
    page: pages.LOCATION
  };
};

/**
 * @param {String} message
 * @param {Object} state
 * @returns {Object}
 */
export const decorateMapSearchTracking = () => {
  return {
    page: pages.MAP_SEARCH
  };
};

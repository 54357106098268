import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { fromTheme } from '@kyruus/ui-theme';

export const HeaderContent = styled.section`
  padding: ${fromTheme('spacing_medium')};
  text-align: center;
  line-height: ${fromTheme('spacing_large')};
`;

const centeredFlexBoxStyles = css`
  display: flex;
  align-content: center;
  justify-content: center;
`;

export const HeaderText = styled.span`
  ${centeredFlexBoxStyles}
  font-size: ${fromTheme('font_size_heading_4')};
  font-weight: ${fromTheme('font_weight_bold')};
  color: ${fromTheme('color_text')};
`;

export const Handle = styled.div`
  ${centeredFlexBoxStyles}
`;

export const SheetContent = styled.section`
  overflow-y: auto;
  height: 75vh;
`;

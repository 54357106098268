import React from 'react';
import { FormattedMessage } from '@kyruus/intl';
import _startCase from 'lodash/startCase';
import _forEach from 'lodash/forEach';
import { messages } from '../../shared/sort-menu';

function SortMenuItem({ sortOption, idPrefix, handleClick, activeSortVal }) {
  const SORT_BY_PREFIX = `${idPrefix}sort-by`;
  const sortById = `${SORT_BY_PREFIX}-${sortOption.value}`;

  let itemContent = {};
  if (sortOption.value == activeSortVal) {
    itemContent = (
      <strong id={sortById} data-testid={sortById}>
        <FormattedMessage {...sortOption.descriptor} />
      </strong>
    );
  } else {
    itemContent = (
      <a
        id={sortById}
        data-testid={sortById}
        onClick={() => handleClick(sortOption.value)}
      >
        <FormattedMessage {...sortOption.descriptor} />
      </a>
    );
  }
  return <span className="sort-menu-item">{itemContent}</span>;
}

function SortMenu({ sortOptions, idPrefix = '', handleClick, activeSortVal }) {
  _forEach(sortOptions, (option) => {
    option.descriptor = messages[option.descriptor_id] || {
      id: `sortdropdown.${option.descriptor_id}`,
      defaultMessage: _startCase(option.descriptor_id),
      description: `Option for ${option.descriptor_id} sort`
    };
  });

  const sortMenuNodes = sortOptions.map((sortOption) => {
    return (
      <SortMenuItem
        key={sortOption.value}
        sortOption={sortOption}
        idPrefix={idPrefix}
        handleClick={handleClick}
        activeSortVal={activeSortVal}
      />
    );
  });

  return (
    <span id={`${idPrefix}sort-by`} data-testid="SortMenu">
      <span className="sr-only">
        <FormattedMessage {...messages.sortby} />
      </span>
      {sortMenuNodes}
    </span>
  );
}

export default SortMenu;

import React from 'react';
import { FormattedMessage } from '@kyruus/intl';
import { withRouter } from 'react-router-dom';

import CtaModal from '../../../cta-modal';
import { getLogWithProvider } from '../../../tracking/decorators';
import { getProviderDisplayName } from '../../../provider/utils';
import { messages } from '../messages';
import { BOOKING_MODAL } from '../constants';

import useContactPanels from './useContactPanels';

export const BookingModal = withRouter(
  ({ config, provider, log, apptOptions, location, onClose }) => {
    const logWithSelectedProvider = getLogWithProvider(log, provider);

    const handleClose = (event) => {
      // The close event triggered by the close button passes through an event, while the backdrop does not
      const closeTrigger = event ? 'close_icon' : 'backdrop';
      getLogWithProvider(
        log,
        provider
      )(`user_action.alter_view.request_appointment.${closeTrigger}`);
      onClose();
    };

    const { panels, count } = useContactPanels({
      config,
      provider,
      apptOptions,
      logWithSelectedProvider,
      location,
      handleClose
    });
    return (
      <CtaModal
        id={BOOKING_MODAL.ID}
        role={BOOKING_MODAL.ROLE}
        ariaLabelledBy="appt-request-title"
        title={
          <FormattedMessage
            {...messages.header}
            values={{
              providerName: getProviderDisplayName(provider)
            }}
          >
            {(title) => <span id="appt-request-title">{title}</span>}
          </FormattedMessage>
        }
        panels={panels}
        open={true}
        onClose={handleClose}
        panelCount={count}
      />
    );
  }
);

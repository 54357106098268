import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CancelCircleOutline from '@kyruus/react-ikons/CancelCircleOutline';
import Button from '@kyruus/button';
import * as Actions from '../../machines/location-summary/location-summary-actions';
import { getSortOptions } from '../../utils/search-common';
import { getApptOptions } from '../../utils/map-search';

import {
  CLICK_LOCATIONS_PROVIDER_TILE,
  CLICK_LOCATIONS_PROVIDER_TILE_BOOK_APPT
} from '../constants';

import {
  ProviderDrawerWrapperScreenLarge,
  ProviderDrawerCloseBtnWrapperScreenLarge,
  ProviderDrawerSortMenuWrapperScreenLarge,
  ProviderDrawerContentWrapperScreenLarge,
  ProviderListWrapperScreenMediumSmall,
  SortMenuWrapperScreenMediumSmall
} from './styles';

import SideSheet from '../SideSheet';
import { getDefaultCustomerSort } from '../../utils/search-common';

import ProviderList, { FROM_LOCATIONS } from '../../shared/ProviderListV9';
import BottomSheet from '../../shared/BottomSheet';
import SearchSummary from '../../shared/search-summary';
import SortMenu from './sort-menu';
import LoadingOverlay from '../../shared/loading';
import LoadMoreButton from '../../shared/LoadMoreButton';
import ErrorMessage from '../../error/error';

export default function ProviderDrawer({
  onClickHideProviders,
  onClickLoadMore,
  isScreenMediumOrBelow,
  config,
  isLoadingProviders,
  providers,
  totalProviders,
  customerCode,
  productName,
  searchSummary,
  currentQuery,
  locationName,
  locationId,
  fetchProvidersError,
  getUpdatedSearch,
  send,
  log
}) {
  const defaultSort = getDefaultCustomerSort(config);
  const [currentSortVal, setCurrentSortVal] = useState(defaultSort);
  useEffect(() => {
    send({
      type: Actions.NEW_SEARCH,
      data: {
        currentQuery: {
          ...currentQuery,
          sort: currentSortVal
        }
      }
    });
  }, [currentSortVal, send]);

  const closeButtonRef = useCallback((closeButtonNode) => {
    if (closeButtonNode) {
      closeButtonNode.focus();
    }
  }, []);

  // an empty `locations` array will result in no locations rows being rendered
  const providersWithoutLocationData = providers.map(({ provider }) => {
    return {
      ...provider,
      locations: []
    };
  });

  const shouldRenderSuccess =
    (!isLoadingProviders && !fetchProvidersError) || providers.length;
  const canLoadMore = providers.length < totalProviders;
  const isErrored = !isLoadingProviders && fetchProvidersError;

  const searchSummaryComponent = shouldRenderSuccess ? (
    <SearchSummary
      totalProviders={totalProviders}
      searchSummary={searchSummary}
      getUpdatedSearch={getUpdatedSearch}
      locationName={locationName}
      // searchDescription provides context on what was searched for
      // this can carry over from the search page in redux state to the location page
      // it's hidden here in the location page provider drawer so that user doesn't see inapplicable context if they arrived from the search page
      hideSearchDescription={true}
      log={log}
      showClearLink={false}
    />
  ) : null;

  const sortOptionsWithoutDistance = getSortOptions(
    config,
    currentQuery
  ).filter((item) => item.descriptor_id !== 'distance');

  const sortMenu = shouldRenderSuccess ? (
    <SortMenu
      sortOptions={sortOptionsWithoutDistance}
      handleClick={(e) => setCurrentSortVal(e)}
      activeSortVal={currentSortVal}
    />
  ) : null;

  const providerList = shouldRenderSuccess ? (
    <ProviderList
      config={config}
      providers={providersWithoutLocationData}
      customerCode={customerCode}
      productName={productName}
      searchSummary={searchSummary}
      log={log}
      shouldAppendAnalyticsParamsBookOnlineUrl={true}
      profileSummaryParams={{
        from: FROM_LOCATIONS,
        location_id: locationId
      }}
      providerCallbackFunc={(provider) =>
        log(CLICK_LOCATIONS_PROVIDER_TILE, { provider_id: provider.id })
      }
      bookingCallbackFunc={(provider) =>
        log(CLICK_LOCATIONS_PROVIDER_TILE_BOOK_APPT, {
          provider_id: provider.id
        })
      }
      apptOptions={getApptOptions(providers, locationId)}
    />
  ) : null;

  const loadMoreButton =
    shouldRenderSuccess && canLoadMore ? (
      <LoadMoreButton onLoadMoreProvidersClick={onClickLoadMore} />
    ) : null;

  const error = isErrored ? <ErrorMessage isLocationsPage={true} /> : null;

  if (isScreenMediumOrBelow) {
    return (
      <BottomSheet
        handleClose={onClickHideProviders}
        headerText={searchSummaryComponent}
        isOpen={true}
      >
        <LoadingOverlay loading={isLoadingProviders} />
        <ProviderListWrapperScreenMediumSmall data-testid="ProviderDrawerContent">
          <SortMenuWrapperScreenMediumSmall>
            {sortMenu}
          </SortMenuWrapperScreenMediumSmall>
          {error}
          {providerList}
          {loadMoreButton}
        </ProviderListWrapperScreenMediumSmall>
      </BottomSheet>
    );
  }

  return (
    <SideSheet
      anchor="left"
      isOpen={true}
      width="60vw"
      onClose={onClickHideProviders}
    >
      <ProviderDrawerWrapperScreenLarge>
        <LoadingOverlay loading={isLoadingProviders} />
        <ProviderDrawerCloseBtnWrapperScreenLarge>
          <Button
            mode="flat"
            onClick={onClickHideProviders}
            aria-label="Close Provider Drawer"
            className="closeBtn"
            data-testid="CloseProviderDrawerButton"
            ref={closeButtonRef}
          >
            <CancelCircleOutline size="25px" />
          </Button>
        </ProviderDrawerCloseBtnWrapperScreenLarge>
        <ProviderDrawerContentWrapperScreenLarge>
          <ProviderDrawerSortMenuWrapperScreenLarge>
            {searchSummaryComponent}
            {sortMenu}
          </ProviderDrawerSortMenuWrapperScreenLarge>
          {error}
          {providerList}
          {loadMoreButton}
        </ProviderDrawerContentWrapperScreenLarge>
      </ProviderDrawerWrapperScreenLarge>
    </SideSheet>
  );
}

ProviderDrawer.propTypes = {
  onClickHideProviders: PropTypes.func.isRequired,
  isScreenMediumOrBelow: PropTypes.bool,
  config: PropTypes.object.isRequired,
  providers: PropTypes.array,
  isLoadingProviders: PropTypes.bool,
  onClickLoadMore: PropTypes.func.isRequired,
  totalProviders: PropTypes.number,
  customerCode: PropTypes.string.isRequired,
  searchSummary: PropTypes.object.isRequired,
  currentQuery: PropTypes.object.isRequired,
  locationName: PropTypes.string.isRequired,
  fetchProvidersError: PropTypes.bool,
  getUpdatedSearch: PropTypes.func.isRequired,
  log: PropTypes.func.isRequired
};

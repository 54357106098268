import React from 'react';
import { defineMessages, FormattedMessage } from '@kyruus/intl';
import _compact from 'lodash/compact';
import { Link } from 'react-router-dom';

import EventAvailable from '@kyruus/react-ikons/EventAvailable';
import Phone from '@kyruus/react-ikons/Phone';
import MailOutline from '@kyruus/react-ikons/MailOutline';
import MobileTeleHealth from '@kyruus/react-ikons/MobileTeleHealth';
import Warning from '@kyruus/react-ikons/Warning';

import CtaModal from '../cta-modal';

import { getProviderDisplayName_V8, showCTA_V8 } from '../provider/utils';
import SecureNewTabLink from '../utils/secure-new-tab-link';
import { getRelativeParameterizedBookingUrl } from '../utils/getRelativeParameterizedBookingUrl';
import { events, pages, placements } from '../tracking/constants';
import { pageViewEvent } from '../tracking/tracking-utils';
import { isModuleEnabled, MODULES } from 'Common/config';
import { SLOTS_ERROR_STATUS, SLOTS_LOADING_STATUS } from '../utils/constants';

import ProfileAvailability from './ProfileAvailability';
import { scrollToTopCCF } from '../utils/cerner';

const messages = defineMessages({
  bookonlinemessage: {
    id: 'cta.modal.bookonline.message',
    description: 'Call to action text to book an appointment online',
    defaultMessage: 'Book an appointment directly online.'
  },
  requestformmessage: {
    id: 'cta.modal.requestform.message',
    description: 'Call to action text to request an appointment online',
    defaultMessage: 'Request an appointment through our online form.'
  },
  phonemessage: {
    id: 'cta.modal.phone.message',
    description: 'Call to action text to call to book an appointment',
    defaultMessage: "Call and we'll help book your appointment."
  },
  virtualcaremessage: {
    id: 'cta.modal.virtualcare.message',
    description: 'Call to action text to begin a virtual care session',
    defaultMessage: 'Start a virtual care session now.'
  },
  bookonlinebutton: {
    id: 'cta.modal.bookonline.button',
    description: 'Call to action button text to book an appointment online',
    defaultMessage: 'Book Online'
  },
  requestformbutton: {
    id: 'cta.modal.requestform.button',
    description: 'Call to action button text to request an appointment online',
    defaultMessage: 'Contact Form'
  },
  phonebutton: {
    id: 'cta.modal.phone.button',
    description: 'Call to action button text to call to book an appointment',
    defaultMessage: 'Call {phoneNumber}'
  },
  virtualcarebutton: {
    id: 'cta.modal.virtualcare.buttontext',
    description: 'Call to action button text to begin a virtual care session',
    defaultMessage: 'Virtual Care'
  },
  ctabutton: {
    id: 'cta.button',
    description: 'Button text to book an appointment with a provider',
    defaultMessage: 'Book Appointment'
  },
  onlinebooking: {
    id: 'providerlist.badge.onlinebooking',
    description: 'Badge displaying that the provider accepts online booking',
    defaultMessage: 'Online booking'
  },
  appointmentPhoneTitle: {
    id: 'appointment_method.phone_title',
    description: 'Title when visit type is phone',
    defaultMessage: 'Phone Visit'
  },
  appointmentVideoTitle: {
    id: 'appointment_method.video_title',
    description: 'Title when visit type is video',
    defaultMessage: 'Video Visit'
  },
  header: {
    id: 'cta.modal.header',
    description: 'Book an appointment call to action modal header',
    defaultMessage: 'Book an Appointment with {providerName}'
  },
  availability: {
    id: 'providerlist.badge.availability',
    description:
      'Badge displaying that the provider is available within a certain number of days',
    defaultMessage: `Available within {days, plural,
      one {# day}
      other {# days}
    }`
  },
  moreAvailabilitiesLinkText: {
    id: 'availability.moreavailabilities',
    description: 'Text to display on link to view more availabilities',
    defaultMessage: 'View All Appointments'
  },
  availabilityErrorMsg: {
    id: 'availability.error',
    description: 'Message to display when call to availability fails',
    defaultMessage:
      "We're currently experiencing difficulties fetching availability for {displayName}."
  },
  availabilityNoMoreRetriesMsg: {
    id: 'availability.nomoreretries',
    description:
      'Message to display when user can no longer retry call to get slots',
    defaultMessage:
      "Unfortunately we can't fetch availability for {displayName} right now. Please call {phoneNumber} to schedule an appointment."
  },
  availabilityTryAgainBtnText: {
    id: 'availability.tryagain',
    description: 'Text for try availability "try again" button',
    defaultMessage: 'Try again.'
  },
  showAvailabilitiesBtnText: {
    id: 'availability.showavailabilities',
    description: 'Text for availability CTA button',
    defaultMessage: 'Show Available Appointments'
  },
  noAvailabilityMessage: {
    id: 'availability.noavailability',
    description: 'Message to display when a location has no available slots',
    defaultMessage:
      "{displayName} doesn't have any availability for this location."
  },
  profileAvailabilityEditBtnText: {
    id: 'availability.profile.edit',
    description:
      "Text for the button to edit patient relationship and appointment purpose on a provider's profile page.",
    defaultMessage: 'Edit'
  },
  profileAvailabilityHeaderText: {
    id: 'availability.profile.header',
    description:
      'Header text to display above availability on provider profile pages.',
    defaultMessage: 'Appointment Availability'
  },
  ctaNoAvailabilityFallbackMessage: {
    id: 'cta.modal.bookonline_noavail.message',
    description:
      'Text to display in the CTA panel rendered when a provider has no online booking availability and no other booking options configured',
    defaultMessage:
      'There are currently no appointments for online booking. Please call the provider to schedule an appointment.'
  },
  ctaNoAvailabilityFallbackConfirmButtonText: {
    id: 'cta.modal.bookonline_noavail.button',
    description:
      'Text for confirm button when the no_availability_fallback panel is rendered',
    defaultMessage: 'OK'
  }
});

const smallBtnClass = 'button full-width btn-m hidden-md hidden-lg';
const largeBtnClass = 'button full-width btn-l hidden-xs hidden-sm';

const panelFormat = {
  book_online: {
    labelMessageDescriptor: messages.bookonlinemessage,
    buttonClass: 'btn-positive kyruus-config-quaternary-color',
    buttonTextMessageDescriptor: messages.bookonlinebutton,
    buttonId: 'book-appt-online-link',
    containerId: 'modal-book-now',
    icon: EventAvailable
  },
  request_form: {
    labelMessageDescriptor: messages.requestformmessage,
    buttonClass: 'btn-link kyruus-config-secondary-color',
    buttonTextMessageDescriptor: messages.requestformbutton,
    buttonId: 'request-appt-online-link',
    containerId: 'modal-request-form',
    icon: MailOutline,
    logName: 'form'
  },
  phone: {
    labelMessageDescriptor: messages.phonemessage,
    buttonClass: 'modal-phone-button btn-link kyruus-config-secondary-color',
    buttonTextMessageDescriptor: messages.phonebutton,
    buttonId: 'request-appt-phone-link',
    containerId: 'modal-phone',
    icon: Phone,
    hrefPrefix: 'tel:'
  },
  virtual_care: {
    labelMessageDescriptor: messages.virtualcaremessage,
    buttonClass: 'btn-positive kyruus-config-quaternary-color',
    buttonTextMessageDescriptor: messages.virtualcarebutton,
    buttonId: 'virtual-care-link',
    containerId: 'modal-virtual-care',
    icon: MobileTeleHealth
  },
  no_availability_fallback: {
    labelMessageDescriptor: messages.ctaNoAvailabilityFallbackMessage,
    buttonClass: 'btn-positive kyruus-config-quaternary-color',
    buttonTextMessageDescriptor:
      messages.ctaNoAvailabilityFallbackConfirmButtonText,
    buttonId: 'no-availability-fallback-button',
    containerId: 'modal-virtual-care',
    icon: Warning,
    largeButton: (_contact, handleCloseCtaModal) => {
      return (
        <button
          className="btn-positive kyruus-config-quaternary-color button full-width btn-l"
          onClick={handleCloseCtaModal}
        >
          <FormattedMessage
            {...messages.ctaNoAvailabilityFallbackConfirmButtonText}
          />
        </button>
      );
    }
  }
};

function ContactPanel({
  contact,
  contactType,
  log,
  target,
  providerId,
  config,
  handleCloseCtaModal
}) {
  const crmEnabled = isModuleEnabled(config, MODULES.CRM_INTEGRATION);
  const useCSRouting = crmEnabled && contactType === 'book_online';
  const LinkComponent = useCSRouting ? Link : SecureNewTabLink;
  const format = panelFormat[contactType];
  const url = (format.hrefPrefix || '') + contact;
  let parsedURL;

  try {
    parsedURL = new URL(url, window.location.origin);
  } catch (e) {
    throw new Error(`Invalid url: ${url}, in ContactPanel.`, e);
  }

  // The target prop causes Link component to do an ssr redirect,
  // so only include it when we're okay with that
  const routingProps = useCSRouting
    ? {
        to: parsedURL.pathname
      }
    : {
        target: target,
        href: parsedURL.href
      };

  const logClick = () =>
    log(
      `user_action.alter_view.request_appointment.${
        format.logName || contactType
      }`,
      { provider_id: providerId }
    );

  let largeButton = null;
  if (format.largeButton) {
    largeButton = format.largeButton(contact, handleCloseCtaModal);
  } else {
    largeButton = (
      <LinkComponent
        data-testid={useCSRouting ? 'client-side-link' : 'ssr-link'}
        className={`${format.buttonClass} ${largeBtnClass}`}
        id={format.buttonId}
        onClick={logClick}
        {...routingProps}
      >
        <FormattedMessage
          {...format.buttonTextMessageDescriptor}
          values={{ phoneNumber: contact }}
        />
      </LinkComponent>
    );
  }

  return (
    <div className="panel make-appt-box">
      <div id={format.containerId} className="modal-message">
        <div className="ta-c modal-icon-container">
          <format.icon className="modal-icon" />
        </div>
        <h3 className="fc-gray-1 fs-m modal-text">
          <FormattedMessage {...format.labelMessageDescriptor} />
        </h3>
      </div>
      {largeButton}
      {contactType !== 'no_availability_fallback' ? (
        <LinkComponent
          data-testid={useCSRouting ? 'client-side-link' : 'ssr-link'}
          className={`${format.buttonClass} ${smallBtnClass}`}
          id={format.buttonId}
          onClick={logClick}
          {...routingProps}
        >
          <FormattedMessage
            {...format.buttonTextMessageDescriptor}
            values={{ phoneNumber: contact }}
          />
        </LinkComponent>
      ) : null}
    </div>
  );
}

class CallToAction extends React.Component {
  constructor(props) {
    super(props);
    this.toggleShow = this.toggleShow.bind(this);
    this.logButtonClick = this.logButtonClick.bind(this);
    this.state = { showModal: false };
  }

  logButtonClick({ btnText } = {}) {
    const { log, buttonLocation } = this.props;

    log('user_action.alter_view.request_appointment', {
      //button_text: '', //TODO: use formatted message text instead. https://kyruus.jira.com/browse/KP-14422
      button_location: buttonLocation
    });

    // This tracking is meant to replace `user_action.alter_view` but we're using both
    // while transitioning
    const trackByBtnLocation = {
      search_results: () => ({
        placement: placements.PROVIDER_CARD,
        button_text: btnText
      }),
      in_navbar: () => ({
        placement: placements.NAV,
        button_text: btnText
      }),
      under_photo: () => ({
        placement: placements.PROFILE,
        button_text: btnText
      })
    };

    if (trackByBtnLocation[buttonLocation]) {
      log(events.USER_ACTION_CTA, trackByBtnLocation[buttonLocation]());
    }
  }

  toggleShow(event) {
    const { log } = this.props;

    if (this.state.showModal) {
      // The close event triggered by the close button passes through an event, while the backdrop does not
      const closeTrigger = event ? 'close_icon' : 'backdrop';
      log(`user_action.alter_view.request_appointment.${closeTrigger}`);
    } else {
      const btnText = event ? event.target.innerText : 'UNKNOWN';
      this.logButtonClick({ btnText });
    }

    this.setState(
      {
        showModal: !this.state.showModal
      },
      () => {
        if (this.state.showModal) {
          scrollToTopCCF();
          // treat showing the modal as a "page event"
          log(pageViewEvent(pages.CTA_MODAL));
        }
      }
    );
  }

  shouldOpenDirectBookSameWindow() {
    const { config } = this.props;
    return isModuleEnabled(config, MODULES.DIRECT_BOOK);
  }

  render() {
    const { provider, config, location } = this.props;

    if (!showCTA_V8(provider, config)) {
      return null;
    }
    const {
      modal_display: {
        skip_modal: skipModal,
        virtual_care: virtualCareOrg
      } = {}
    } = this.props.config;

    const providerPhone = provider.contact_phone;
    const providerRequestUrl = provider.request_appointment_url;
    const providerBookUrl = provider.book_online_url;

    const bookUrlTarget = this.shouldOpenDirectBookSameWindow()
      ? '_self'
      : undefined;

    const providerVirtualCareUrl = provider.virtual_care_url;

    const virtualCareEnabled = providerVirtualCareUrl && virtualCareOrg;

    const request_enabled =
      providerPhone ||
      providerRequestUrl ||
      providerBookUrl ||
      virtualCareEnabled;
    const disabled = request_enabled ? '' : 'disabled';
    const buttonAlign = this.props.buttonAlign
      ? `f-${this.props.buttonAlign}`
      : 'full-width';
    const baseClass = `button btn-link btn-${
      this.props.buttonSize || 'm'
    } kyruus-config-secondary-color ${buttonAlign}`;
    // the boolean skipModal comes from an org's config and indicates that the call to action button should bypass the modal
    // however, this feature is only active if the org does not have both direct booking and virtual care configured
    // in the case that skipModal is turned on and direct booking and virtual care are active,
    // we render the modal so the user can select which option they prefer
    const canSkipModal =
      skipModal &&
      (providerBookUrl || virtualCareEnabled) &&
      !(providerBookUrl && virtualCareEnabled);
    if (canSkipModal) {
      return (
        <SecureNewTabLink
          className={`${
            providerBookUrl ? 'direct-book-now-link' : ''
          } ${baseClass}`}
          href={
            getRelativeParameterizedBookingUrl({
              provider,
              config,
              location
            }) || providerVirtualCareUrl
          }
          target={bookUrlTarget}
          name="button"
          onClick={this.logButtonClick}
          rel="nofollow"
        >
          {providerBookUrl ? (
            <FormattedMessage {...messages.ctabutton} />
          ) : (
            <FormattedMessage {...messages.virtualcarebutton} />
          )}
        </SecureNewTabLink>
      );
    } else {
      let bookOnline, phone, requestForm, virtualCare;
      if (providerBookUrl) {
        bookOnline = (
          <ContactPanel
            target={bookUrlTarget}
            contact={getRelativeParameterizedBookingUrl({
              provider,
              config,
              location
            })}
            contactType={'book_online'}
            log={this.props.log}
            config={this.props.config}
          />
        );
      }
      if (providerPhone) {
        phone = (
          <ContactPanel
            contact={providerPhone}
            contactType={'phone'}
            log={this.props.log}
            config={this.props.config}
          />
        );
      }
      if (providerRequestUrl) {
        requestForm = (
          <ContactPanel
            contact={providerRequestUrl}
            contactType={'request_form'}
            log={this.props.log}
            config={this.props.config}
          />
        );
      }
      if (virtualCareEnabled) {
        virtualCare = (
          <ContactPanel
            contact={providerVirtualCareUrl}
            contactType={'virtual_care'}
            log={this.props.log}
            config={this.props.config}
          />
        );
      }

      const panelCount = _compact([
        bookOnline,
        requestForm,
        phone,
        virtualCare
      ]).length;
      let modal = null;
      if (panelCount > 0) {
        modal = (
          <CtaModal
            id="appt-request"
            title={
              <FormattedMessage
                {...messages.header}
                values={{
                  providerName: getProviderDisplayName_V8(provider)
                }}
              />
            }
            panels={[bookOnline, phone, requestForm, virtualCare]}
            open={this.state.showModal}
            onClose={this.toggleShow}
            panelCount={panelCount}
          />
        );
      }

      return (
        <div>
          <button
            type="button"
            className={`request-appointment ${baseClass} ${disabled}`}
            onClick={this.toggleShow}
            data-testid="CtaModalTriggerBtn"
          >
            <FormattedMessage {...messages.ctabutton}>
              {(message) => (
                <span data-provider-id={this.props.provider.id}>{message}</span>
              )}
            </FormattedMessage>
          </button>
          {modal}
        </div>
      );
    }
  }
}

const OnlineBookingBadge = (
  <div className="online-booking fc-gray-2">
    <span className="icon-event-available fc-positive fs-l va-m" />
    <span className="va-m">
      &nbsp;
      <FormattedMessage {...messages.onlinebooking} />
    </span>
  </div>
);

function AvailabilityBadge(provider) {
  const availability = provider.availability_density_best;
  if (availability) {
    return (
      <div className="available-online fc-gray-2 pt-s">
        <span className="icon-schedule fc-positive fs-l va-m" />
        <span className="va-m">
          &nbsp;
          <FormattedMessage
            {...messages.availability}
            values={{ days: availability }}
          />
          *
        </span>
      </div>
    );
  }
}

const VideoVisitBadge = (
  <div className="online-booking fc-gray-2 pt-s">
    <span
      className="fc-positive fs-l va-m"
      style={{ position: 'relative', top: '-2px' }}
    >
      <MobileTeleHealth className="fc-positive fs-l va-m" />
    </span>
    <span className="va-m">
      &nbsp;
      <FormattedMessage {...messages.appointmentVideoTitle} />
    </span>
  </div>
);

const PhoneVisitBadge = (
  <div className="online-booking fc-gray-2 pt-s">
    <span className="icon-phone fc-positive fs-l va-m" />
    <span className="va-m">
      &nbsp;
      <FormattedMessage {...messages.appointmentPhoneTitle} />
    </span>
  </div>
);

// COPIED FROM NEW PROVIDER TILE
function getVisitType(provider) {
  const visitType = {};
  // Short-circuit if no appointment_ehr_purposes (like "provider_type":"Virtual Care"; see KENG-13699)
  if (provider.appointment_ehr_purposes) {
    for (const appointment of provider.appointment_ehr_purposes) {
      visitType[appointment.ehr_data[0].visit_method] = true;
      // Terminate early if we got the fields we care about
      if (visitType['video'] && visitType['phone']) {
        break;
      }
    }
  }

  return visitType;
}

function SchedulingOptions({
  apptInfo,
  provider,
  config,
  log,
  buttonLocation,
  fetchSlots = () => {},
  slotsByProviderId = {},
  slotsStatus,
  availablePurposesByProviderId,
  fetchPurposesByProviderId,
  location,
  tokens
}) {
  if (showCTA_V8(provider, config)) {
    const isProfileSummary = buttonLocation == 'under_photo';
    const ProviderOnlineBookingBadge =
      provider.book_online_url && OnlineBookingBadge;
    const availabilityBadge = AvailabilityBadge(provider);
    const Divider = isProfileSummary && (
      <hr className="mt-m mr-xs mb-m ml-xs" aria-hidden="true" />
    );

    const visitType = getVisitType(provider);
    const hasPhoneVisit = visitType['phone'];
    const hasVideoVisit = visitType['video'];
    const isOndemandProvider = provider.provider_type === 'Virtual Care';
    const isVirtualProvider = hasVideoVisit || isOndemandProvider;

    const groupAvailByLocId = (provider, slotsByProviderId) => {
      const { locations = [] } = provider;
      // map of locationIds
      const availByLocId = locations.reduce((acc, loc) => {
        acc[loc.id] = [];
        return acc;
      }, {});

      // push to each location accordingly
      Object.keys(slotsByProviderId).length &&
        slotsByProviderId[provider.id] &&
        slotsByProviderId[provider.id].slots.forEach((availability) => {
          availByLocId[availability.location_id] &&
            availByLocId[availability.location_id].push(availability);
        });
      return availByLocId || {};
    };

    const groupedAvail = groupAvailByLocId(provider, slotsByProviderId);

    const determineShouldRenderCtaNotAvail = (apptInfo, groupedAvail) => {
      // always render CTA if availability-in-PMC isn't configured
      if (!config.display_availability_in_search) {
        return true;
      }

      // if apptInfo is falsy (provider had no slots in state and no avail query params on profile URL)
      // or if the call to fetch slots errored, render the CTA instead of availability-tiles
      if (!apptInfo || slotsStatus[provider.id] === SLOTS_ERROR_STATUS) {
        return true;
      }

      // if the slots call is pending, always display the loading state (render availability-tiles, not the CTA)
      if (slotsStatus[provider.id] === SLOTS_LOADING_STATUS) {
        return false;
      }

      // by this point, the call to fetch slots has returned successfully
      // if none of the provider's locations have avail, render the CTA button
      // otherwise, render availability-tiles
      const atLeastOneLocHasAvail = Object.entries(groupedAvail).some(
        ([, slots]) => Boolean(slots.length)
      );

      return !atLeastOneLocHasAvail;
    };

    return (
      <div className="hidden-print">
        {Divider}
        {determineShouldRenderCtaNotAvail(apptInfo, groupedAvail) ? (
          <CallToAction
            provider={provider}
            buttonSize={isProfileSummary ? 'xl' : 'm'}
            buttonLocation={buttonLocation}
            config={config}
            log={log}
            location={location}
            tokens={tokens}
          />
        ) : (
          <ProfileAvailability
            apptInfo={apptInfo}
            provider={provider}
            availByLocId={groupedAvail}
            slotsStatus={slotsStatus}
            messages={messages}
            config={config}
            Divider={Divider}
            fetchSlots={fetchSlots}
            availablePurposesByProviderId={availablePurposesByProviderId}
            fetchPurposesByProviderId={fetchPurposesByProviderId}
            log={log}
            location={location}
            tokens={tokens}
          />
        )}
        {ProviderOnlineBookingBadge && (
          <div
            className={`badge-container ${
              isProfileSummary ? 'pt-s' : 'child-fs-xs'
            }`}
          >
            {ProviderOnlineBookingBadge}
          </div>
        )}
        {availabilityBadge && (
          <div
            className={`badge-container ${
              isProfileSummary ? 'pt-s' : 'child-fs-xs'
            }`}
          >
            {availabilityBadge}
          </div>
        )}
        {isVirtualProvider && (
          <div
            className={`badge-container ${
              isProfileSummary ? 'pt-s' : 'child-fs-xs'
            }`}
          >
            {VideoVisitBadge}
          </div>
        )}
        {hasPhoneVisit && (
          <div
            className={`badge-container ${
              isProfileSummary ? 'pt-s' : 'child-fs-xs'
            }`}
          >
            {PhoneVisitBadge}
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
}

export { ContactPanel, CallToAction, SchedulingOptions, messages };
export default SchedulingOptions;

import {
  CONSUMER_TRACKING_TOKEN,
  SEARCH_TOKEN,
  SEARCH_ID_TOKEN,
  USER_TOKEN,
  getTokenValue
} from 'Common/logging/tokens';

import { isServerSide } from 'Common/utils';

import {
  PERSISTED_TOKENS,
  getPersistedTokenValue
} from '../tracking/persisted-tokens';

/**
 * Get query params to append to dbw /book urls
 * @param  {Object} config - customer service config
 * @param  {Object} tokens - tokens object from the store
 * @returns {Object} object with query params
 */
export function getDbwTrackingParams({ config }: { config: any }) {
  const { locale } = config;

  const params: Record<string, any> = {};

  if (isServerSide()) {
    // skip user-specific params on server render, both because we don't have access
    // to localStorage for `persisted tokens` and we don't want user params to get indexed
    return params;
  }

  [CONSUMER_TRACKING_TOKEN, SEARCH_TOKEN, USER_TOKEN, SEARCH_ID_TOKEN].forEach(
    (trackingTokenDef) => {
      const tokenValue = getTokenValue({ token: trackingTokenDef });
      if (tokenValue) {
        params[trackingTokenDef.queryParamName as string] = tokenValue;
      }
    }
  );

  const tokenKeys = Object.keys(PERSISTED_TOKENS) as Array<
    keyof typeof PERSISTED_TOKENS
  >; // copy all persisted tokens onto the dbw url.
  // don't copy everything from 'tokens' because not all tokens need to be persisted
  tokenKeys.forEach((key) => {
    const value = getPersistedTokenValue(key);
    if (value && typeof value === 'string') {
      params[PERSISTED_TOKENS[key].queryParam] = value;
    }
  });

  // if we added any params, also add timestamp for validation
  if (Object.keys(params).length > 0) {
    params.timestamp = `${new Date().getTime()}`;
  }

  if (locale === 'es-US') {
    params.locale = config.locale;
  }

  return params;
}

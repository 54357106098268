import { defineMessages } from '@kyruus/intl';

export const messages = defineMessages({
  drawerTitle: {
    id: 'search.availability_drawer.title',
    description: 'Availability drawer title',
    defaultMessage: 'Schedule Appointment'
  },
  closeDrawer: {
    id: 'search.availability_drawer.close',
    description: 'Close drawer button',
    defaultMessage: 'Close'
  },
  phoneNumber: {
    id: 'search.availability_drawer.phone_number',
    description: 'Provider phone number text',
    defaultMessage: 'Need help scheduling? Call {phone}'
  }
});

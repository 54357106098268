import React from 'react';

export default function Video({
  url,
  accessibilityText,
  containerClass,
  iframeId,
  testId,
  width,
  height
}) {
  return url ? (
    <div
      className={containerClass}
      data-testid={testId}
      aria-label={accessibilityText}
    >
      <iframe
        id={iframeId}
        title={accessibilityText}
        type="text/html"
        src={url}
        frameBorder="0"
        // eslint-disable-next-line react/no-unknown-property
        autohide="1"
        width={width}
        height={height}
        allowFullScreen={true}
        webkitallowfullscreen=""
        mozallowfullscreen=""
      ></iframe>
    </div>
  ) : null;
}

const defaultProps = {
  accessibilityText: '',
  containerClass: '',
  iframeId: '',
  width: '100%',
  height: '600'
};

Video.defaultProps = defaultProps;

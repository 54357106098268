import React from 'react';
import { ContactPanel } from '../../../provider/scheduling-options';
import { getRelativeParameterizedBookingUrl } from '../../../utils/getRelativeParameterizedBookingUrl';

import { getBookingUrlTarget } from 'Src/utils/search-common';
import { getProviderContactPhone, getActiveContactPanels } from '../utils';

export default ({
  config,
  provider,
  apptOptions,
  logWithSelectedProvider,
  location,
  handleClose
}) => {
  const panels = [];
  const providerPhone = getProviderContactPhone(provider);
  const { bookOnline, fallback, phone, requestForm, virtualCare } =
    getActiveContactPanels(config, provider);

  if (bookOnline) {
    panels.push(
      <ContactPanel
        target={getBookingUrlTarget(config)}
        contact={getRelativeParameterizedBookingUrl({
          provider,
          config,
          location,
          apptOptions
        })}
        contactType="book_online"
        log={logWithSelectedProvider}
        providerId={provider.id}
      />
    );
  }
  if (phone) {
    panels.push(
      <ContactPanel
        contact={providerPhone}
        contactType="phone"
        log={logWithSelectedProvider}
        providerId={provider.id}
      />
    );
  }
  if (requestForm) {
    panels.push(
      <ContactPanel
        contact={provider.request_appointment_url}
        contactType="request_form"
        log={logWithSelectedProvider}
        providerId={provider.id}
      />
    );
  }
  if (virtualCare) {
    panels.push(
      <ContactPanel
        contact={provider.virtual_care_url}
        contactType="virtual_care"
        log={logWithSelectedProvider}
        providerId={provider.id}
      />
    );
  }

  if (fallback) {
    panels.push(
      <ContactPanel
        contact={null}
        contactType="no_availability_fallback"
        log={logWithSelectedProvider}
        providerId={provider.id}
        handleCloseCtaModal={handleClose}
      />
    );
  }
  return {
    panels,
    // design wants the fallback panel to take up the space of 2 normal panels
    // setting panelCount to 2 achieves this requirement
    // https://kyruus.jira.com/browse/KENG-40044 for details
    count: fallback ? 2 : panels.length
  };
};

import React, { useState } from 'react';
import { injectIntl, FormattedMessage } from '@kyruus/intl';
import { withRouter } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import _get from 'lodash/get';

import { fromTheme } from '@kyruus/ui-theme';
import ProviderTileNext, {
  PLACEMENT_TYPE_SEARCH
} from '@kyruus/provider-tile-next';
import {
  ProviderReferralButton,
  ProviderReferralList
} from '@kyruus/provider-referral';

import {
  MODULES,
  isModuleEnabled,
  shouldRenderDirectBookInDrawer
} from 'Common/config';
import { shouldOpenDirectBookSameWindow } from 'Src/utils/search-common';
import {
  getProviderUrl,
  getProviderSummaryUrl,
  getProviderDisplayName,
  getProviderPhoneNumber
} from '../../provider/utils';
import { pages } from '../../tracking/constants';
import { pageViewEvent } from '../../tracking/tracking-utils';
import { getLogWithProvider } from '../../tracking/decorators';
import { getRelativeParameterizedBookingUrl } from '../../utils/getRelativeParameterizedBookingUrl';
import { locationsModuleEnabled, buildLocationURL } from '../../utils/location';

import { MAX_AVAILABILITIES_TO_DISPLAY } from '../../utils/constants';
import { scrollToTopCCF } from '../../utils/cerner';
import {
  useReferralCrmConnector,
  InvalidPatientDataMessage
} from '../../referral-crm-connector';
import { messages } from './messages';
import {
  getProviderSlotsAndAppointmentInfo,
  getProviderSlotsStatus
} from './utils';

import { AvailabilityDrawer } from './AvailabilityDrawer';
import {
  AVAILABILITY_DRAWER,
  BOOKING_MODAL,
  TILE_CLICK_ORIGIN
} from './constants';
import { BookingModal } from './BookingModal';

function ProviderList({
  appointmentInfo,
  apptOptions, // appointment options to be passed as deepLinkingParam
  availabilityLinkTarget,
  bookingCallbackFunc = () => {},
  config,
  customerCode,
  history,
  intl,
  location,
  log,
  match,
  onAvailabilityTilesLoaded,
  onClickShowAvailability,
  onTimeSlotsClick,
  onViewMoreClick, // callback when clicking view more availability tiles button
  productName,
  profileSummaryParams = {}, // extra params to send to send along to provider profile pages
  providerCallbackFunc = () => {}, // provider title and ratings callback function
  providers = [],
  searchSummary,
  slots, // slots by provider id
  slotsStatus, // status by provider id,
  fetchProviderAvailability
}) {
  const [showProviderModal, setShowProviderModal] = useState(undefined);
  const [selectedDate, setSelectedDate] = useState(undefined);
  const showAvailabilityModal = showProviderModal?.modal === 'availability';
  const showBookingModal = showProviderModal?.modal === 'booking';

  const theme = useTheme();
  const color_text_contrast = fromTheme('color_text_contrast')({ theme });

  const isLocationsModuleEnabled = locationsModuleEnabled(config);

  const { enabled: crmEnabled, connector } = useReferralCrmConnector(config);

  // Get modal display
  const virtualCareOrg = _get(config, 'modal_display.virtual_care_org');
  const skipModal = _get(config, 'modal_display.skip_modal');

  const bookingCallbackFunction = (provider) => {
    return (_e) => {
      bookingCallbackFunc(provider);
      const providerBookUrl = provider.book_online_url;
      const providerVirtualCareUrl = provider.virtual_care_url;
      const virtualCareEnabled = providerVirtualCareUrl && virtualCareOrg;
      // the boolean skipModal comes from an org's config and indicates that the call to action button should bypass the modal
      // however, this feature is only active if the org does not have both direct booking and virtual care configured
      // in the case that skipModal is turned on and direct booking and virtual care are active,
      // we render the modal so the user can select which option they prefer.
      // If we are rendering direct book in a drawer, always skip the modal.
      const canSkipModal =
        shouldRenderDirectBookInDrawer(config) ||
        (skipModal &&
          (providerBookUrl || virtualCareEnabled) &&
          !(providerBookUrl && virtualCareEnabled));
      if (canSkipModal) {
        const url =
          getRelativeParameterizedBookingUrl({
            provider,
            config,
            location
          }) || provider.virtual_care_url;
        if (shouldRenderDirectBookInDrawer(config)) {
          // Push the current URL onto the history stack
          // along with the selected provider data as part of the location state
          // as required for react-router v4 modal routing.
          // See https://v5.reactrouter.com/web/example/modal-gallery for more info.
          history.push(url, {
            provider: {
              location,
              provider
            }
          });
        } else if (shouldOpenDirectBookSameWindow()) {
          window.open(url, '_self');
        } else {
          const win = window.open(url, '_blank');
          if (win != null) {
            win.focus();
          }
        }
      } else {
        scrollToTopCCF();
        // treat showing the modal as a "page event"
        getLogWithProvider(log, provider)(pageViewEvent(pages.CTA_MODAL));

        setShowProviderModal({ provider, modal: 'booking' });
      }
    };
  };

  const providerCallbackFunction = (provider, hash = '', slots) => {
    return (e) => {
      providerCallbackFunc(provider);
      const url = getProviderSummaryUrl(
        provider,
        hash,
        slots,
        profileSummaryParams,
        location,
        match
      );
      if (e.ctrlKey || e.metaKey) {
        window.open(url);
      } else {
        // force a server side render so that the redux context is reset with the search v9 provider (vs the v8 provider)
        window.open(url, '_self');
        // TODO: once search v9 migration is complete on the searchmatch page, switch to the more performant react-router history:
        // history.push(url);
      }
    };
  };

  const locationCallbackFunction = (locationUrl, isExternalUrl = false) => {
    log('user_action.display_location_profile_page');

    if (isExternalUrl && typeof window !== 'undefined') {
      window.open(locationUrl, '_blank', 'noopener');
    }
    if (!isExternalUrl) {
      history.push(locationUrl);
    }
  };

  const handleTimeSlotsClick = (
    origin,
    { date, time, providerId, href, unformattedDate, provider }
  ) => {
    if (origin === TILE_CLICK_ORIGIN.PROVIDER_TILE) {
      const useHeadsUpAvailability = _get(
        config,
        'darkship_feature_flags.provider_tile_heads_up_availability',
        false
      );
      if (useHeadsUpAvailability) {
        setShowProviderModal({ provider, modal: 'availability' });
        setSelectedDate(unformattedDate);
      }
    }
    onTimeSlotsClick(date, time, providerId, href);
  };

  const handleViewMoreClick = (provider_id, href) => {
    handleCloseModalPanel();
    onViewMoreClick(provider_id, href);
  };

  const handleCloseModalPanel = () => {
    setShowProviderModal(undefined);
    setSelectedDate(undefined);
  };

  const providerCards = providers.map((provider, index) => {
    // from this point on the data access paths will need to change
    const slotsAndAppointmentInfo = getProviderSlotsAndAppointmentInfo(
      provider,
      slots
    );
    const displayName = getProviderDisplayName(provider);
    const phone = getProviderPhoneNumber(provider);

    return (
      <ProviderTileNext
        applyV9SummaryData={true}
        provider={provider}
        customerCode={customerCode}
        productName={productName}
        providerLocations={provider.locations}
        bookOnlineUrl={getRelativeParameterizedBookingUrl({
          provider,
          config,
          location
        })}
        config={config}
        searchSummary={searchSummary}
        track={getLogWithProvider(log, provider)}
        bookingCallback={bookingCallbackFunction(provider)}
        reviewCallback={providerCallbackFunction(
          provider,
          '#profile-reviews',
          slots
        )}
        reviewUrl={getProviderUrl({
          provider,
          location,
          match,
          hash: '#profile-reviews'
        })}
        locationsUrl={getProviderUrl({
          provider,
          location,
          match,
          hash: '#profile-locations'
        })}
        locationsCallback={providerCallbackFunction(
          provider,
          '#profile-locations',
          slots
        )}
        summaryCallback={providerCallbackFunction(provider, '', slots)}
        summaryUrl={getProviderSummaryUrl(
          provider,
          '',
          slots,
          profileSummaryParams,
          location,
          match
        )}
        key={provider.id}
        lazyLoadImg={index > 2}
        onClickShowAvailability={() => onClickShowAvailability(provider)}
        availabilityTilesMessages={{
          moreAvailabilitiesLinkText: (
            <FormattedMessage {...messages.moreAvailabilitiesLinkText} />
          ),
          showAvailabilitiesBtnText: (
            <FormattedMessage {...messages.showAvailabilitiesBtnText} />
          ),
          noAvailabilityMessage: (
            <FormattedMessage
              {...messages.noAvailabilityMessage}
              values={{ displayName }}
            />
          ),
          availabilityErrorMsg: (
            <FormattedMessage
              {...messages.availabilityErrorMsg}
              values={{ displayName }}
            />
          ),
          availabilityNoMoreRetriesMsg: (
            <FormattedMessage
              {...messages.availabilityNoMoreRetriesMsg}
              values={{
                displayName,
                phoneNumber: phone
              }}
            />
          ),
          availabilityTryAgainBtnText: (
            <FormattedMessage {...messages.availabilityTryAgainBtnText} />
          ),
          showAvailabilitiesBtnAriaLabel: intl.formatMessage(
            messages.showAvailabilitiesBtnAriaLabelText,
            { providerName: displayName }
          ),
          slotsAriaLabel: (date, time, locationName) =>
            intl.formatMessage(messages.providerAvailabilitySlotsAriaLabel, {
              date,
              time,
              providerName: displayName,
              locationName
            }),
          viewMoreAriaLabel: intl.formatMessage(
            messages.providerAvailabilityViewMoreAriaLabel,
            { providerName: displayName }
          )
        }}
        availabilityLinkTarget={availabilityLinkTarget}
        maxAvailabilitiesToDisplay={MAX_AVAILABILITIES_TO_DISPLAY}
        onTimeSlotsClick={(date, time, providerId, href, unformattedDate) =>
          handleTimeSlotsClick(TILE_CLICK_ORIGIN.PROVIDER_TILE, {
            date,
            time,
            providerId,
            href,
            unformattedDate,
            provider
          })
        }
        onViewMoreClick={handleViewMoreClick}
        onAvailabilityTilesLoaded={onAvailabilityTilesLoaded}
        getLocationUrl={(location) =>
          buildLocationURL(
            isLocationsModuleEnabled,
            location,
            searchSummary.query_string
          )
        }
        locationCallback={locationCallbackFunction}
        apptInfo={slotsAndAppointmentInfo?.apptInfo}
        slots={slotsAndAppointmentInfo?.slots}
        slotsStatus={getProviderSlotsStatus(provider, slotsStatus)}
        locationCTA={
          isModuleEnabled(config, MODULES.REFERRAL)
            ? (props) => (
                <ProviderReferralButton
                  referrals={connector.state.context.referrals}
                  onAddReferral={connector.addReferral}
                  onRemoveReferral={connector.removeReferral}
                  canRefer={connector.canRefer(connector.state)}
                  hasMaxReferrals={connector.hasMaxReferrals(connector.state)}
                  referableProvider={{ ...props, config: config }}
                />
              )
            : null
        }
        placementType={PLACEMENT_TYPE_SEARCH}
        bookingWarning={
          crmEnabled && (
            <InvalidPatientDataMessage
              type="errorBooking"
              invalidFields={
                connector.state.context.validationErrors.booking?.data
              }
              color={color_text_contrast}
            />
          )
        }
        hasBookingWarning={
          isModuleEnabled(config, MODULES.DIRECT_BOOK) &&
          crmEnabled &&
          connector.hasPatientValidationErrorsForBooking()
        }
        isBookingDisabled={crmEnabled && !connector.canBook(connector.state)}
        isProfileDisabled={!isModuleEnabled(config, MODULES.PROFILE)}
        slotsAriaProps={{
          expanded: showAvailabilityModal,
          controls: AVAILABILITY_DRAWER.ID,
          hasPopup: AVAILABILITY_DRAWER.ROLE
        }}
        bookingOptionsButton={{
          onClick: bookingCallbackFunction(provider),
          ariaControls: BOOKING_MODAL.ID,
          ariaHasPopup: BOOKING_MODAL.ROLE,
          ariaExpanded: showBookingModal
        }}
      />
    );
  });

  return (
    <div
      id="provider-list"
      data-testid="ProviderList"
      className="result-column"
    >
      {providerCards}
      {isModuleEnabled(config, MODULES.REFERRAL) && (
        <ProviderReferralList
          referrals={connector.state.context.referrals}
          maxReferralCount={connector.state.context.maxReferralCount}
          canRefer={connector.canRefer(connector.state)}
          hasReferrals={connector.hasReferrals(connector.state)}
          isReferDisabled={connector.isReferDisabled(connector.state)}
          showSpinner={connector.showSpinner(connector.state)}
          onRemoveReferral={connector.removeReferral}
          onSendReferralData={(referralMetaData) => {
            log('user_action.provider_detail.refer_provider', {
              referrals: connector.state.context.referrals.map((ref) => ({
                provider_id: ref.provider.id,
                location_id: ref.location.id
              }))
            });
            connector.sendReferralData(referralMetaData);
          }}
          referralMetaData={{ searchSummary }}
        />
      )}
      {showBookingModal && (
        <BookingModal
          config={config}
          provider={showProviderModal.provider}
          log={log}
          apptOptions={apptOptions}
          location={location}
          onClose={handleCloseModalPanel}
        />
      )}
      {showAvailabilityModal && (
        <AvailabilityDrawer
          location={location}
          config={config}
          provider={showProviderModal.provider}
          onClose={handleCloseModalPanel}
          log={getLogWithProvider}
          appointmentInfo={appointmentInfo}
          onViewMoreClick={handleViewMoreClick}
          fetchProviderAvailability={fetchProviderAvailability}
          selectedDate={selectedDate}
          bookOnlineUrl={getRelativeParameterizedBookingUrl({
            provider: showProviderModal.provider,
            config,
            location
          })}
          onTimeSlotsClick={(
            date,
            time,
            providerId,
            href,
            unformattedDate,
            provider
          ) =>
            handleTimeSlotsClick(TILE_CLICK_ORIGIN.AVAILABILITY_DRAWER, {
              date,
              time,
              providerId,
              href,
              unformattedDate,
              provider
            })
          }
        />
      )}
    </div>
  );
}

export default withRouter(injectIntl(ProviderList));

import { defineMessages } from '@kyruus/intl';

export const messages = defineMessages({
  header: {
    id: 'cta.modal.header',
    description: 'Book an appointment call to action modal header',
    defaultMessage: 'Book an Appointment with {providerName}'
  },
  moreAvailabilitiesLinkText: {
    id: 'availability.moreavailabilities',
    description: 'Text to display on link to view more availabilities',
    defaultMessage: 'View All Appointments'
  },
  availabilityErrorMsg: {
    id: 'availability.error',
    description: 'Message to display when call to availability fails',
    defaultMessage:
      "We're currently experiencing difficulties fetching availability for {displayName}."
  },
  availabilityNoMoreRetriesMsg: {
    id: 'availability.nomoreretries',
    description:
      'Message to display when user can no longer retry call to get slots',
    defaultMessage:
      "Unfortunately we can't fetch availability for {displayName} right now. Please call {phoneNumber} to schedule an appointment."
  },
  availabilityTryAgainBtnText: {
    id: 'availability.tryagain',
    description: 'Text for try availability "try again" button',
    defaultMessage: 'Try again.'
  },
  showAvailabilitiesBtnText: {
    id: 'availability.showavailabilities',
    description: 'Text for availability CTA button',
    defaultMessage: 'Show Available Appointments'
  },
  showAvailabilitiesBtnAriaLabelText: {
    id: 'availability.showAvailabilitiesBtnAriaLabel',
    description:
      'Accessibility text to be read by screen reader for availability CTA button',
    defaultMessage: 'Show Available Appointments for {providerName}'
  },
  noAvailabilityMessage: {
    id: 'availability.noavailability',
    description: 'Message to display when a location has no available slots',
    defaultMessage:
      "{displayName} doesn't have any availability for this location."
  },
  providerAvailabilitySlotsAriaLabel: {
    id: 'availability.slotsAriaLabel',
    description:
      'Accessibility text to be read by screen reader for provider available slots',
    defaultMessage: '{date} and {time} with {providerName} at {locationName}'
  },
  providerAvailabilityViewMoreAriaLabel: {
    id: 'availability.viewMoreAriaLabel',
    description:
      'Accessibility text to be read by screen reader for provider view more slots',
    defaultMessage: 'view all available appointments for {providerName}'
  }
});

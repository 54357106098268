import React, {
  ReactNode,
  useCallback,
  ReactElement,
  SyntheticEvent
} from 'react';
import { injectIntl, WrappedComponentProps } from '@kyruus/intl';
import Drawer from '@kyruus/drawer';
import Cancel from '@kyruus/react-ikons/Cancel';
import Button from '@kyruus/button';

import { Content, Header, Footer, DrawerContent } from './styles';
import { messages } from './messages';

interface BaseSideSheetProps {
  /**
   * Side from which the drawer will appear.
   * @default 'right'
   */
  anchor?: 'bottom' | 'left' | 'right' | 'top';
  /** The id of the element used to title the side sheet */
  ariaLabelledBy: string;
  children: ReactNode;
  dataTestId?: string;
  footer?: ReactNode;
  header: ReactNode;
  id: string;
  onClose: (e: SyntheticEvent, reason: string) => void;
  width: string;
}

type SideSheetProps = WrappedComponentProps & BaseSideSheetProps;

/**
 * Side sheets are surfaces containing supplementary content that are
 * anchored to the left or right edge of the screen.
 */
export const SideSheet = injectIntl(
  ({
    anchor = 'right',
    ariaLabelledBy,
    children,
    dataTestId,
    footer,
    header,
    id,
    intl,
    onClose,
    width
  }: SideSheetProps) => {
    const closeButtonRef = useCallback((closeButtonNode) => {
      if (closeButtonNode) {
        closeButtonNode.focus();
      }
    }, []);

    return (
      <Drawer
        id={id}
        data-testid={dataTestId}
        anchor={anchor}
        onClose={onClose}
        open={true}
        variant="temporary"
        aria-labelledby={ariaLabelledBy}
        role="dialog"
      >
        <DrawerContent width={width}>
          <Header>
            {header}
            <Button
              onClick={(e: SyntheticEvent) => onClose(e, 'closeClick')}
              mode="flat"
              data-testid="close-drawer-button"
              ref={closeButtonRef}
              aria-label={intl.formatMessage(messages.closeDrawer)}
            >
              <Cancel size="1.6em" aria-hidden="true" />
            </Button>
          </Header>
          <Content>{children}</Content>
          {footer && <Footer>{footer}</Footer>}
        </DrawerContent>
      </Drawer>
    );
  }
) as any as (props: BaseSideSheetProps) => ReactElement;
